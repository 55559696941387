import { put, takeLatest, call, select } from "redux-saga/effects";
import {
    GET_REPORTS_LIST_ACTION,
    GET_REPORTS_LOADING,
    GET_REPORTS_SUCCESS,
    GET_REPORTS_ERROR,
    DOWNLOAD_REPORTS_LIST_ACTION,
    DOWNLOAD_REPORTS_LIST_START,
    DOWNLOAD_REPORTS_LIST_COMPLETE,
} from "./types";
import Api from "./api";
import { Constants } from "../../lib/constant";



function* getReportsListSaga(action) {
    yield put({ type: GET_REPORTS_LOADING, payload: action.payload });
    try {
        const filters = yield select((state) => state.reportsReducer.filters);
        let res = yield call(Api.getReportsList, filters);
        if (res?.data?.summaries?.length) 
            yield put({ type: GET_REPORTS_SUCCESS, payload: res.data.summaries });
        else
            yield put({
                type: GET_REPORTS_ERROR,
                payload: [Constants.ERROR.INTERNAL_SERVER],
            });
    } catch (error) {
        yield put({ type: GET_REPORTS_ERROR, payload: error.message });
    }
}

function* downloadReportsListSaga(action) {
    const { data, fileName } = action.payload;

    try {
        yield put({ type: DOWNLOAD_REPORTS_LIST_START });

         const transformedData = data?.data?.map((item) => ({
           employee_id: item.employee_id,
           first_name: item.first_name,
           last_name: item.last_name,
           total_amount: parseFloat(item.total_amount) || 0,
         }));
        
        const newData = {data: transformedData, fileName: data?.fileName}
        
        
        yield call(Api.downloadReportsList, newData, fileName)
    } catch(error) {
        console.error("Error in downloadReportsListSaga:", error);
    } finally {

        yield put({ type: DOWNLOAD_REPORTS_LIST_COMPLETE });

    }

}


function* watchReportsAsync() {
    yield takeLatest(GET_REPORTS_LIST_ACTION, getReportsListSaga);
    yield takeLatest(DOWNLOAD_REPORTS_LIST_ACTION, downloadReportsListSaga);
}

export default ( watchReportsAsync);