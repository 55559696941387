import React, { useCallback, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import CommonDropDown from "../dropdown";
import CommonDatePicker from "../datepicker";
import EndDatePicker from "../datepicker/endDatePicker";
import theme from "../../theme";
import {
  getOverviewAction,
  setOverviewFilters,
} from "../../containers/dashboard/action";
import { getCurrenciesAction } from "../../containers/login/action";
import Loading from "../loading";
import CanvasJSReact from "../../lib/canvasjs.react";
import { moneyValue } from "../../utils";
const CanvasJSChart = CanvasJSReact.CanvasJSChart;
let companyOptions = [],
  currencyOptions = [{ value: "USD", label: "US Dollar" }];

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: theme.colors.White,
    width: "100%",
    minHeight: "calc(100vh - 185px)",
    padding: "45px 90px",
    marginLeft: "220px",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .title": {
      color: theme.colors.Violet,
      fontFamily: theme.fontFamily.MARKPROMEDIUM,
      fontSize: "24px",
    },
    "& .filterContainer": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& .title": {
        color: theme.colors.DodgerBlueLight,
        fontFamily: theme.fontFamily.MARKPROMEDIUM,
        fontSize: "16px",
        marginRight: "15px",
      },
    },
  },
  tabContainer: {
    marginTop: "25px",
    "& .tab": {
      display: "flex",
      alignItems: "center",
      backgroundColor: theme.colors.White,
      boxShadow: "0px 2px 15px #D0CFDB99",
      borderRadius: "10px",
      padding: "20px 30px",
      "& img": {
        marginRight: "20px",
      },
      "& .title": {
        color: theme.colors.Violet,
        fontSize: "14px",
        fontFamily: theme.fontFamily.MARKPRO,
      },
      "& .value": {
        color: theme.colors.Violet,
        fontSize: "24px",
        fontFamily: theme.fontFamily.MARKPROHEAVY,
        "& .currency": {
          fontSize: "14px",
          position: "relative",
          bottom: "6px",
        },
      },
    },
  },
  chartContainer: {
    marginTop: "25px",
    "& .innerContainer": {
      backgroundColor: theme.colors.White,
      boxShadow: "0px 2px 15px #D0CFDB99",
      borderRadius: "10px",
      padding: "30px 40px",
      "& .title": {
        fontSize: "16px",
        fontFamily: theme.fontFamily.MARKPROBOOK,
      },
      "& .chart": {
        display: "flex",
        "& div:first-child": {
          width: "250px",
        },
      },
      "& .chartLabelContainer": {
        display: "flex",
        "& .point": {
          height: "15px",
          width: "15px !important",
          borderRadius: "50%",
          position: "absolute",
        },
        "& .label": {
          color: theme.colors.Violet,
          fontSize: "14px",
          fontFamily: theme.fontFamily.MARKPROBOOK,
        },
        "& .value": {
          color: theme.colors.Violet,
          fontSize: "14px",
          fontFamily: theme.fontFamily.MARKPROHEAVY,
        },
        "& .currency": {
          fontSize: "10px",
          position: "relative",
          bottom: "3px",
        },
      },
    },
  },
  middleTextContainer: {
    position: "absolute",
    zIndex: "2",
    top: "48%",
    left: "15%",
    transform: "translateY(-50%) translateX(-15%)",
    textAlign: "center",
    "& .label": {
      color: theme.colors.Violet,
      fontSize: "14px",
      fontFamily: theme.fontFamily.MARKPROBOOK,
    },
    "& .value": {
      color: theme.colors.Violet,
      fontSize: "14px",
      fontFamily: theme.fontFamily.MARKPROHEAVY,
    },
    "& .currency": {
      fontSize: "10px",
      position: "relative",
      bottom: "3px",
    },
  },
}));

function Overview() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const getOverviewMethod = useCallback(
    (data) => dispatch(getOverviewAction(data)),
    [dispatch]
  );

  const setOverviewFiltersMethod = useCallback(
    (data) => dispatch(setOverviewFilters(data)),
    [dispatch]
  );

  const getCurrenciesMethod = useCallback(
    () => dispatch(getCurrenciesAction()),
    [dispatch]
  );

  const dashboardReducer = useSelector((state) => {
    return state.dashboardReducer;
  });

  const loginReducer = useSelector((state) => {
    return state.loginReducer;
  });

  const [selectedCompany, setSelectedCompany] = useState(
    dashboardReducer?.overviewFilters?.selectedCompany || {
      value: 0,
      label: "All",
    }
  );
  const [selectedCurrency, setSelectedCurrency] = useState(
    dashboardReducer?.overviewFilters?.selectedCurrency || {
      value: "NGN",
      label: "Naira",
    }
  );

  const [startDate, setStartDate] = useState(() => {
    if (dashboardReducer?.overviewFilters?.startDate) {
      return new Date(dashboardReducer?.overviewFilters?.startDate);
    }
    const currentDate = new Date();
    const previousMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      21
    );
    return previousMonth.setHours(0, 0, 0, 0);
  });

  const [endDate, setEndDate] = useState(() => {
    if (dashboardReducer?.overviewFilters?.endDate) {
      return new Date(dashboardReducer?.overviewFilters?.endDate);
    }
    const currentDate = new Date();
    const endOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      20
    );
    return endOfMonth.setHours(23, 59, 59, 999);
  });

  // const [startDateSelected, setStartDateSelected] = useState(false);
  // const [endDateSelected, setEndDateSelected] = useState(false);

  useEffect(() => {
    getCurrenciesMethod();
  }, [getCurrenciesMethod]);

  useEffect(() => {
    if (loginReducer?.companyOptions?.length) {
      companyOptions = [];
      companyOptions.push({ value: 0, label: "All" });
      for (let i = 0; i < loginReducer?.companyOptions.length; i++) {
        const each = loginReducer?.companyOptions[i];
        if (
          companyOptions.findIndex(
            (rec) => rec.value && rec.value === each.id
          ) < 0
        )
          companyOptions.push({ value: each.id, label: each.name });
      }
    }

    if (loginReducer?.currencyOptions?.length) {
      for (let i = 0; i < loginReducer?.currencyOptions.length; i++) {
        const each = loginReducer?.currencyOptions[i];
        if (currencyOptions.findIndex((rec) => rec.value === each.id) < 0)
          currencyOptions.push({ value: each.id, label: each.name });
      }
    }
  }, [loginReducer]);

  useEffect(() => {
    let filters = {};

    if (selectedCompany?.value) {
      filters.company_id = selectedCompany?.value;
    }
    // if (selectedCurrency?.value) {
    //   filters.currency = selectedCurrency?.value;
    // }

    if (startDate) {
      const start = new Date(startDate);
      filters.start_time = new Date(
        start.getFullYear(),
        start.getMonth(),
        21
      ).setUTCHours(24, 0, 0, 0);
      filters.start_time = new Date(filters.start_time).toISOString();
    }

    if (endDate) {
      const end = new Date(endDate);
      filters.end_time = new Date(
        end.getFullYear(),
        end.getMonth(),
        21
      ).setUTCHours(23, 59, 59, 999);
      filters.end_time = new Date(filters.end_time).toISOString();
    }

    getOverviewMethod(filters);

    setOverviewFiltersMethod({
      selectedCompany,
      selectedCurrency,
      startDate,
      endDate,
    });
  }, [
    selectedCompany,
    selectedCurrency,
    startDate,
    endDate,
    getOverviewMethod,
    setOverviewFiltersMethod,
  ]);

  useEffect(() => {
    if (dashboardReducer?.error?.length) {
      const ele = document.getElementsByClassName("error-container");
      if (ele && ele.length) ele[0].scrollIntoView(false);
    }
  }, [dashboardReducer]);

  return (
    <div className={classes.root}>
      <Loading
        showLoader={dashboardReducer?.loading || loginReducer?.loading}
      />

      {dashboardReducer?.error?.length ? (
        <div className={"error-container"}>
          {Array.isArray(dashboardReducer.error) &&
            dashboardReducer.error.map((each, index) => (
              <div key={index}>{each}</div>
            ))}
        </div>
      ) : null}

      <div className={classes.headerContainer}>
        <div className="title">Overview</div>
        <div className="filterContainer">
          <div className="title">Filter by</div>
          <div style={{ width: "180px", marginRight: "15px" }}>
            <CommonDropDown
              placeholder={"Select Company"}
              options={companyOptions}
              value={selectedCompany}
              onChange={(value) => {
                setSelectedCompany(value);
              }}
            />
          </div>
          <div style={{ width: "180px", marginRight: "15px" }}>
            <CommonDropDown
              placeholder={"Select Currency"}
              options={currencyOptions}
              value={selectedCurrency}
              onChange={(value) => {
                setSelectedCurrency(value);
              }}
            />
          </div>
          <div>
            <CommonDatePicker
              startDate={startDate}
              setStartDate={(date) => {
                setStartDate(new Date(new Date(date).setHours(0, 0, 0, 0)));
              }}
            />
          </div>
          <div>
            <EndDatePicker
              endDate={endDate}
              setEndDate={(date) => {
                setEndDate(new Date(new Date(date).setHours(23, 59, 59, 999)));
              }}
            />
          </div>
        </div>
      </div>

      <Grid container className={classes.tabContainer} spacing={2}>
        <Grid item xs={6}>
          <div className="tab">
            <div>
              <img src="/images/Salary Icon.svg" alt="Salary Icon" />
            </div>
            <div>
              <div className="title">Total Monthly Net Salaries</div>
              <div className="value">
                <span className="currency">
                  {dashboardReducer?.companyOverview?.currency}
                </span>
                {moneyValue(
                  dashboardReducer?.companyOverview?.monthly_net_salaries
                )}
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="tab">
            <div>
              <img src="/images/Fees Icon.svg" alt="Fees Icon" />
            </div>
            <div>
              <div className="title">Total Revenue</div>
              <div className="value">
                <span className="currency">
                  {dashboardReducer?.companyOverview?.currency}
                </span>
                {moneyValue(dashboardReducer?.companyOverview?.total_revenue)}
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="tab">
            <div>
              <img src="/images/Employees Icon.svg" alt="Employees Icon" />
            </div>
            <div>
              <div className="title">Total Number of Registered Employees</div>
              <div className="value">
                {dashboardReducer?.companyOverview?.registered_employees}
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="tab">
            <div>
              <img src="/images/Employees Icon.svg" alt="Employees Icon" />
            </div>
            <div>
              <div className="title">Number of Employees</div>
              <div className="value">
                {dashboardReducer?.companyOverview?.number_of_employees}
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="tab">
            <div>
              <img src="/images/Employees Icon.svg" alt="Employees Icon" />
            </div>
            <div>
              <div className="title">Active Users</div>
              <div className="value">
                {dashboardReducer?.companyOverview?.total_active_employees}
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="tab">
            <div>
              <img src="/images/paystack.svg" alt="Employees Icon" />
            </div>
            <div>
              <div className="title">Paystack Available Balance</div>
              <div className="value">
                {moneyValue(dashboardReducer?.companyOverview?.paystack_balances[0].balance / 100)}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>

      <Grid container className={classes.chartContainer} spacing={2}>
        <Grid item md={6} xs={12}>
          <div className="innerContainer">
            <div className="title">
              Total Transaction Count
              <br /> VS
              <br /> Pending Transaction Count
            </div>
            <div className="chart">
              <div style={{ position: "relative" }}>
                <div className={classes.middleTextContainer}>
                  <div className="label">Pending Vs Total</div>
                  <div className="value">
                    {isNaN(
                      (dashboardReducer?.companyOverview
                        ?.total_pending_trsc_count /
                        dashboardReducer?.companyOverview?.total_trsc_count) *
                        100
                    ) ||
                    dashboardReducer?.companyOverview?.total_trsc_count === 0
                      ? "0"
                      : (
                          (dashboardReducer?.companyOverview
                            ?.total_pending_trsc_count /
                            dashboardReducer?.companyOverview
                              ?.total_trsc_count) *
                          100
                        ).toFixed(2) + "%"}
                  </div>
                </div>
                <CanvasJSChart
                  options={{
                    height: 250,
                    width: 250,
                    animationEnabled: true,
                    data: [
                      {
                        explodeOnClick: false,
                        innerRadius: "85%",
                        legendMarkerType: "square",
                        name: "New vs Returning Visitors",
                        radius: "100%",
                        showInLegend: false,
                        startAngle: 90,
                        type: "doughnut",
                        dataPoints: [
                          {
                            y: dashboardReducer?.companyOverview
                              ?.total_trsc_count,
                            //   name: "Remaining Available Amounts",
                            color: theme.colors.DodgerBlue,
                          },
                          {
                            y: dashboardReducer?.companyOverview
                              ?.total_pending_trsc_count,
                            //   name: "Total Net Salaries",
                            color: theme.colors.Violet,
                          },
                        ],
                      },
                    ],
                  }}
                />
              </div>
              <div style={{ marginTop: 100 }}>
                <div
                  className="chartLabelContainer"
                  style={{ marginBottom: "15px" }}
                >
                  <div
                    className="point"
                    style={{ backgroundColor: theme.colors.DodgerBlue }}
                  ></div>
                  <div style={{ marginLeft: "20px" }}>
                    <div className="label">
                      Total Transaction
                      <br /> Count
                    </div>
                    <div className="value">
                      {moneyValue(
                        dashboardReducer?.companyOverview?.total_trsc_count
                      )}
                    </div>
                  </div>
                </div>
                <div className="chartLabelContainer">
                  <div
                    className="point"
                    style={{ backgroundColor: theme.colors.Violet }}
                  ></div>
                  <div style={{ marginLeft: "20px" }}>
                    <div className="label">Pending Transaction Count</div>
                    <div className="value">
                      {moneyValue(
                        dashboardReducer?.companyOverview
                          ?.total_pending_trsc_count
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item md={6} xs={12}>
          <div className="innerContainer">
            <div className="title">
              Total Withdrawal Fees
              <br /> VS
              <br /> Total Withdrawals
            </div>
            <div className="chart">
              <div style={{ position: "relative" }}>
                <div className={classes.middleTextContainer}>
                  <div className="label">Total</div>
                  <div className="value">
                    {
                      // Check if total withdrawals sum is available and not zero
                      dashboardReducer?.companyOverview?.total_withdrawals &&
                      dashboardReducer?.companyOverview?.total_withdrawals !== 0
                        ? (
                            (dashboardReducer?.companyOverview?.total_revenue /
                              dashboardReducer?.companyOverview
                                ?.total_withdrawals) *
                            100
                          ).toFixed(2) + "%"
                        : "0"
                    }
                  </div>
                </div>
                <CanvasJSChart
                  style={{ width: "500px" }}
                  options={{
                    height: 250,
                    width: 250,
                    animationEnabled: true,
                    data: [
                      {
                        explodeOnClick: false,
                        innerRadius: "85%",
                        legendMarkerType: "square",
                        name: "New vs Returning Visitors",
                        radius: "100%",
                        showInLegend: false,
                        startAngle: 90,
                        type: "doughnut",
                        dataPoints: [
                          {
                            y: dashboardReducer?.companyOverview?.total_revenue,
                            //   name: "Remaining Available Amounts",
                            color: theme.colors.Violet,
                          },
                          {
                            y: dashboardReducer?.companyOverview
                              ?.total_withdrawals,
                            //   name: "Total Net Salaries",
                            color: theme.colors.DodgerBlue,
                          },
                        ],
                      },
                    ],
                  }}
                />
              </div>
              <div style={{ marginTop: 100 }}>
                <div
                  className="chartLabelContainer"
                  style={{ marginBottom: "15px" }}
                >
                  <div
                    className="point"
                    style={{ backgroundColor: theme.colors.Violet }}
                  ></div>
                  <div style={{ marginLeft: "20px" }}>
                    <div className="label">Total Withdrawal Fees</div>
                    <div className="value">
                      <span className="currency">
                        {dashboardReducer?.companyOverview?.currency}
                      </span>
                      {moneyValue(dashboardReducer?.companyOverview?.total_revenue)}
                    </div>
                  </div>
                </div>
                <div className="chartLabelContainer">
                  <div
                    className="point"
                    style={{ backgroundColor: theme.colors.DodgerBlue }}
                  ></div>
                  <div style={{ marginLeft: "20px" }}>
                    <div className="label">Total Withdrawals</div>
                    <div className="value">
                      <span className="currency">
                        {dashboardReducer?.companyOverview?.currency}
                      </span>
                      {moneyValue(dashboardReducer?.companyOverview?.total_withdrawals)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Overview;
