/* eslint-disable import/no-anonymous-default-export */
import { format } from 'date-fns';
import coreApi from "../../lib/coreApi";
import * as XLSX from "xlsx";


function stringToBlob(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}

export default {
    getReportsList: (filters) => {
        let url = `/admin/payments/employees-2`;

        if (filters && filters.employeeId) {
            url += `?employee_id=${filters.employeeId}`;
        }

        if (filters.startDate || filters.endDate) {
            const { startDate, endDate } = filters;
            if (startDate) {
                 url += `${url.includes('?') ? '&' : '?'}start_date=${startDate}`;
            }
            if (endDate) {
                url += `&end_date=${endDate}`;
            }
        } else if (!filters.startDate || !filters.endDate) {
            // If not provided, calculate the start and end dates for the current month
            const currentDate = new Date();
            const startOfMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            const endOfMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

            // Update filters with the calculated dates
            filters.startDate = format(startOfMonthDate, 'yyyy-MM-dd');
            filters.endDate = format(endOfMonthDate, 'yyyy-MM-dd');

            url += `${url.includes('?') ? '&' : '?'}start_date=${filters.startDate}&end_date=${filters.endDate}`;
        }

        let result = coreApi.GET(url);
        return result;
    },
    downloadReportsList: async (data) => {
        try {
            if (data.data && data.data.length > 0) {
                const ws = XLSX.utils.json_to_sheet(data.data);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, "Reports");
                // Use XLSX.write to create a binary string
                const binaryString = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
                // Convert the binary string to a Blob
                const blob = new Blob([stringToBlob(binaryString)], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                // Create download link
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", data.fileName);
                // Trigger download
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                console.error("Error downloading file: Data is empty");
            }
        } catch (error) {
            console.error("Error downloading file:", error);
        }
    },
    
};
