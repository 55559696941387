import React, { useEffect, useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import { moneyValue } from "../../utils";
import { getReportsListAction, downloadReportsListAction } from "../../containers/reports/action"; // Assuming you have this action
import theme from "../../theme";
import CommonTable from "../table";


const useStyles = makeStyles(() => ({
  root: {
    margin: "0 auto",
    width: "60%",
  },
  headerName: {
    fontSize: "13px",
    fontFamily: theme.fontFamily.MARKPROHEAVY,
    opacity: "0.3",
    color: theme.colors.Violet,
    textAlign: "center",
  },
  headerBody:{
    fontSize: "13px",
    
    textAlign: "center",
  },
  Tab: {
    position: "relative",
    top: "31px",
    left: "-22px",
    cursor: "pointer",
    width: "10px", // Increase the width to make it wider
    "& .tableTitle": {
      borderRadius: "10px 10px 0px 0px",
      fontFamily: theme.fontFamily.MARKPROMEDIUM,
      fontSize: "16px",
      color: theme.colors.DodgerBlue,
      cursor: "pointer",
      padding: "15px",
      top: `50%`,
      left: `1004%`,
      transform: `translate(-50%,-50%)`,
      position: "absolute",
      textAlign: "center", // Make the title text centered
    },
  },
  currency: {
    // fontSize: "8px",
    position: "absolute",
    // top: "0.2px",
    padding: "55px",
    marginLeft: "300px",
    marginRight: "300px"
    // borderRadius: "14px",
  },
  input: {
    border: '1px solid #ccc',
    borderRadius: '14px',
    // padding: theme.spacing(1, 2),
    outline: 'none',
    '&:focus': {
      borderColor: theme.colors.DodgerBlue,
    },
    color: theme.colors.DodgerBlue,
    fontWeight: 'bold',
    borderColor: theme.colors.DodgerBlue

  },
  buttonContainer: {
    position: "relative",
  },
  button1: {
    backgroundColor:'white',
    position: "absolute",
    marginRight: "-175px",
    marginLeft: "193px",
    marginTop: "5px",
    marginBottom: "-500px",
    


    borderRadius: '14px',
    cursor: 'pointer',
    outline: 'none',
    '&:hover': {
      backgroundColor: theme.colors.DodgerBlue,
    },
    borderColor: theme.colors.DodgerBlue
  },
  button2: {
    backgroundColor:'white',
    position: "absolute",
    marginLeft: "282px",
    marginTop: "5px",
    borderRadius: '14px',
    cursor: 'pointer',
    outline: 'none',
    '&:hover': {
      backgroundColor: theme.colors.DodgerBlue,
    },
    borderColor: theme.colors.DodgerBlue
  },
  tube: {
    fontSize: "10px",
    fontFamily: theme.fontFamily.MARKPROBOLD,
    borderRadius: "14px",
    textAlign: "center",
    padding: "5px 10px",
  },
}));



function Reports() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dashboard = useSelector( (state) => state.loginReducer)

  const getReportsListMethod = useCallback(
    (filters) => dispatch(getReportsListAction(filters)),
    [dispatch]
  );

  const handleDownloadExcel = () => {
    const dataToDownload = filteredReports || [];
    dispatch(downloadReportsListAction({ data: dataToDownload, fileName: `${dashboard?.companyInformation?.name.replace(/\s+/g, "-")}-Reports.xlsx`}));
};

  const reportsReducer = useSelector((state) => {
    return state.reportsReducer;
  });

  const currentDate = new Date();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPage, setTotalPage] = useState(1);
  const [filters, setFilters] = useState({
    employeeId: "",
    startDate: new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 22).toISOString(),
    endDate: new Date(currentDate.setDate(20)).toISOString(),
  });
  const page_size = 10;

  const filteredReports = reportsReducer?.reportsList.filter(
    (report) => report.total_amount && report.total_amount !== "0"
  );

  const paginatedReports = filteredReports.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );

  useEffect(() => {
    
      getReportsListMethod(filters);

  }, [getReportsListMethod, filters]);

  useEffect(() => {
    const { reportsList } = reportsReducer;
    if (reportsList?.length) {
      setTotalPage(Math.ceil(reportsList.length / page_size));
    }
  }, [reportsReducer, page_size]);

  const handleFilterChange = (filterName, value) => {
    let newValue = value;

    if (value && (filterName === "startDate" || filterName === "endDate")) {
      newValue = new Date(value).toISOString();
    } else if (!value) {
      newValue = null;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: newValue,
    }));
  };

  const handleApplyFilters = () => {
    setPage(1); 
    getReportsListMethod(filters);
  };

  return (
    <div className={classes.root}>
      <div className={classes.currency}>
        <input
          type="text"
          placeholder="Employee ID"
          value={filters.employeeId}
          onChange={(e) => handleFilterChange("employeeId", e.target.value)}
          className={classes.input}
        />
        <input
          type="date"
          placeholder="Start Date"
          value={filters.startDate ? filters.startDate.split("T")[0] : ""}
          onChange={(e) => handleFilterChange("startDate", e.target.value)}
          className={classes.input}
        />
        <input
          type="date"
          placeholder="End Date"
          value={filters.endDate ? filters.endDate.split("T")[0] : ""}
          onChange={(e) => handleFilterChange("endDate", e.target.value)}
          className={classes.input}
        />
        {/* <Dropdown /> */}
        <div className={classes.buttonContainer}>
          <button onClick={handleApplyFilters} className={classes.button1}>
            Apply Filters
          </button>
          <button onClick={handleDownloadExcel} className={classes.button2}>
            Download Filters
          </button>
        </div>
      </div>

      <div className={classes.Tab}>
        <img src={"/images/Tab2.svg"} alt="ReportsTab"></img>
        <span className="tableTitle">Reports</span>
      </div>

      {!reportsReducer?.loading ? (
        <>
          <CommonTable
            columns={[
              {
                Header: "Employee ID",
                accessor: "employee_id",
                HeaderCell: (data) => {
                  return <div className={classes.headerName}>{data}</div>;
                },
                Cell: (data, record) => {
                  return (
                    <div className={classes.headerBody}>
                      {record.employee_id}
                      {/* {record.employee.employee_id} */}
                    </div>
                  );
                },
              },
              {
                Header: "Employee Name",
                accessor: "employee_name",
                HeaderCell: (data) => {
                  return <div className={classes.headerName}>{data}</div>;
                },
                Cell: (data, record) => {
                  return (
                    <div className={classes.headerBody}>
                      {/* {record.employee.first_name + " " + record.employee.last_name} */}
                      {record.first_name + " " + record.last_name}
                    </div>
                  );
                },
              },
              // {
              //   Header: "Narration",
              //   accessor: "narration",
              //   HeaderCell: (data) => {
              //     return <div className={classes.headerName}>{data}</div>;
              //   },
              //   Cell: (data, record) => {
              //     return (
              //       <div>
              //        {/* {record.intent} */}
              //       </div>
              //     );
              //   },
              // },
              {
                Header: "Total Withdrawn",
                accessor: "amount",
                HeaderCell: (data) => {
                  return <div className={classes.headerName}>{data}</div>;
                },
                Cell: (data, record) => {
                  return (
                    <div className={classes.headerBody}>
                      {/* <span className={classes.currency}>
                        {record?.company?.currency}
                      </span> */}
                      {/* {moneyValue(data)} */}
                      {moneyValue(record.total_amount)+".00"}
                    </div>
                  );
                },
              },
            ]}
            data={paginatedReports}
          />
          {filteredReports.length > rowsPerPage && (
            <div className={"pagination"}>
              <Pagination
                page={page}
                count={Math.ceil(filteredReports.length / rowsPerPage)}
                showFirstButton
                showLastButton
                onChange={(event, value) => {
                  setPage(value);
                }}
              />
            </div>
          )}
        </>
      ) : null}
    </div>
  );
}

export default Reports;